import Vue from "vue";
import type { GetterTree, ActionTree, MutationTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import objectHash from "object-hash";
import _ from "@/boot/lodash";
import type { AxiosRequestConfig } from "axios";
import type { DataModules } from "@/store/modules/data/modules";

export const DEFAULT_LIST_SCOPE = "$list";
export const DEFAULT_SINGLE_SCOPE = "$single";

export interface IDataStateElement {
  data: {};
  params: {};
  hash: string;
  dataUsage: number;
  total?: number;
}

export interface IDataState {
  [scope: string]: IDataStateElement;
}

export interface IDataModuleState {
  [module: string]: IDataState;
}

// Reactively set nested props

export function setProp(obj, props, value) {
  const prop = props.shift();

  if (!obj[prop]) {
    Vue.set(obj, prop, {});
  }
  if (!props.length) {
    if (value && typeof value === "object" && !Array.isArray(value)) {
      Vue.set(obj, prop, { ...obj[prop], ...value });
    } else {
      Vue.set(obj, prop, value);
    }
    return;
  }
  setProp(obj[prop], props, value);
}

// Reactively delete nested props

export function deleteProp(obj, props) {
  const prop = props.shift();
  if (!obj[prop]) {
    return;
  }
  if (!props.length) {
    Vue.delete(obj, prop);
    return;
  }
  deleteProp(obj[prop], props);
}

const moduleGetters: GetterTree<IDataModuleState, IState> = {
  list:
    state =>
    ({ storeModule, scope }) => {
      scope = scope || DEFAULT_LIST_SCOPE;
      const dataPath = [storeModule, scope, "data"]
        .join("/")
        .replace(/\//g, ".");
      return _.get(state, dataPath, null);
    },
  listArray:
    (state, getters) =>
    ({ storeModule, scope }) => {
      scope = scope || DEFAULT_LIST_SCOPE;
      return _.orderBy(
        _.values(getters.list({ storeModule, scope })),
        "_order"
      );
    },
  total:
    state =>
    ({ storeModule, scope }) => {
      scope = scope || DEFAULT_LIST_SCOPE;
      const dataPath = [storeModule, scope, "total"]
        .join("/")
        .replace(/\//g, ".");
      return _.get(state, dataPath, 0);
    },
  single:
    state =>
    ({ storeModule, scope }) => {
      scope = scope || DEFAULT_SINGLE_SCOPE;
      const dataPath = [storeModule, scope, "data"]
        .join("/")
        .replace(/\//g, ".");
      return _.get(state, dataPath, null);
    }
};

const actions: ActionTree<IDataModuleState, IState> = {
  callApi: (
    { dispatch },
    {
      callConfig = new Object(),
      method,
      path,
      requestConfig,
      returnData = true,
      vm
    }: {
      callConfig?: object;
      method: Methods;
      path: string;
      requestConfig: AxiosRequestConfig;
      returnData: boolean;
      vm?: Vue;
    }
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        "api/call",
        {
          method,
          path,
          requestConfig,
          callConfig: { vueInstance: vm, ...callConfig }
        },
        { root: true }
      )
        .then(response => resolve(returnData ? response.data : response))
        .catch(reject);
    });
  },
  fetch: (
    { commit, dispatch, state, rootState },
    {
      callConfig = new Object(),
      handle401Error = true,
      handle403IPError = true,
      handle409FeatureError = true,
      ignoreStored = false,
      merge = false,
      method = Methods.GET,
      params,
      path,
      returnData = true,
      scope,
      splitCount = false,
      storeData = true,
      storeModule,
      vm
    }: {
      callConfig?: object;
      handle401Error: boolean;
      handle403IPError: boolean;
      handle409FeatureError: boolean;
      ignoreStored: boolean;
      merge: boolean;
      method: Methods;
      params: any;
      path: string;
      returnData: boolean;
      scope: string;
      splitCount: boolean;
      storeData: boolean;
      storeModule: DataModules;
      vm?: Vue;
    }
  ) => {
    const dataHash = objectHash.sha1({
      params,
      path,
      brandId: rootState.auth.admin.brandId
    });
    const dataPath = [storeModule, scope].join("/").replace(/\//g, ".");
    const useSplitCountLogic =
      _.has(params, "limit") &&
      params.limit !== "count" &&
      storeData &&
      splitCount;

    let total: any = null;

    return new Promise((resolve, reject) => {
      if (!ignoreStored && _.get(state, `${dataPath}.hash`) === dataHash) {
        total = _.get(state, `${dataPath}.total`);

        return resolve({
          data: _.get(state, `${dataPath}.data`),
          params: params,
          total,
          messages: _.get(state, `${dataPath}.messages`)
        });
      }

      if (useSplitCountLogic) {
        const countCallParams = { ...params, limit: "count" };
        delete countCallParams.with;
        delete countCallParams.order;
        delete countCallParams.offset;

        dispatch("callApi", {
          callConfig,
          method,
          path,
          requestConfig: {
            params: { ...params, skip_count: 1 },
            handle401Error,
            handle403IPError,
            handle409FeatureError
          },
          returnData: false,
          vm
        })
          .then(resolve)
          .catch(reject);

        dispatch("callApi", {
          callConfig,
          method,
          path,
          requestConfig: {
            params: countCallParams,
            handle401Error,
            handle403IPError,
            handle409FeatureError
          },
          returnData: false,
          vm
        }).then(counterResults => {
          total = counterResults.total;
          if (_.get(state, `${dataPath}.total`) !== undefined)
            setProp(state, `${dataPath}.total`.split("."), total);
        });
      } else {
        dispatch("callApi", {
          callConfig,
          method,
          path,
          requestConfig: {
            params,
            handle401Error,
            handle403IPError,
            handle409FeatureError
          },
          returnData: false,
          vm
        })
          .then(resolve)
          .catch(reject);
      }
    }).then(result => {
      const data = returnData ? _.get(result, "data", null) : result;

      if (!storeData) return data;

      commit(`setData`, {
        storeModule,
        scope,
        merge,
        data: _.get(result, "data"),
        total: useSplitCountLogic ? total : _.get(result, "total"),
        messages: _.get(result, "messages", null),
        params: params,
        hash: dataHash
      });

      commit(`setDependant`, {
        storeModule,
        scope,
        dependant: _.get(vm, "$cuid", "CUID_UNSET")
      });

      return data;
    });
  },
  search: ({ dispatch }, payload) => {
    return dispatch("fetch", {
      ...payload,
      scope: _.get(payload, "scope") || DEFAULT_LIST_SCOPE
    });
  },
  list: ({ dispatch }, payload) => {
    return dispatch("fetch", {
      ...payload,
      scope: _.get(payload, "scope") || DEFAULT_LIST_SCOPE
    });
  },
  get: ({ dispatch }, payload) => {
    return dispatch("fetch", {
      ...payload,
      scope: _.get(payload, "scope") || DEFAULT_SINGLE_SCOPE
    });
  },
  create: (
    { dispatch },
    {
      path,
      data,
      vm
    }: {
      path: string;
      data: any;
      vm?: Vue;
    }
  ) => {
    return dispatch("callApi", {
      method: Methods.POST,
      path,
      requestConfig: { data },
      vm
    });
  },
  update: (
    { dispatch, commit },
    {
      path,
      data,
      storeModule,
      key,
      scope,
      vm
    }: {
      path: string;
      data: any;
      storeModule: DataModules;
      key?: string;
      scope?: string;
      vm?: Vue;
    }
  ) => {
    return new Promise((resolve, reject) => {
      dispatch("callApi", {
        method: Methods.PUT,
        path,
        requestConfig: { data },
        vm
      })
        .then(result => {
          if (scope) {
            commit(`updateData`, {
              storeModule,
              scope,
              data,
              key
            });
          }
          resolve(result);
        })
        .catch(reject);
    });
  },
  remove: (
    { dispatch, commit },
    {
      path,
      data,
      storeModule,
      scope,
      keys = [],
      vm
    }: {
      path: string;
      data: any;
      storeModule: DataModules;
      scope?: string;
      keys?: string[];
      vm?: Vue;
    }
  ) => {
    return new Promise((resolve, reject) => {
      dispatch("callApi", {
        method: Methods.DELETE,
        path,
        requestConfig: { data },
        vm
      })
        .then(result => {
          if (scope) {
            commit(`binData`, {
              scope,
              keys,
              storeModule
            });
          }
          resolve(result);
        })
        .catch(reject);
    });
  },
  binList: ({ dispatch }, payload) => {
    return dispatch("bin", {
      ...payload,
      scope: payload.scope || DEFAULT_LIST_SCOPE
    });
  },
  binSingle: ({ dispatch }, payload) => {
    return dispatch("bin", {
      ...payload,
      scope: payload.scope || DEFAULT_SINGLE_SCOPE
    });
  },
  bin: (
    { state, commit },
    {
      storeModule,
      scope,
      vm,
      ignoreDependants,
      keys = []
    }: {
      storeModule: DataModules;
      scope: string;
      vm?: Vue;
      ignoreDependants?: boolean;
      keys?: string[];
    }
  ) => {
    const dependant = _.get(vm, "$cuid", "CUID_UNSET");
    const dependants = _.get(
      state,
      `${storeModule.replace(/\//g, ".")}.${scope}.dependants`,
      []
    );
    _.pull(dependants as [], dependant); // Mutate array (removing dependants)
    if (dependants.length && !ignoreDependants) {
      commit("unsetDependant", { storeModule, scope, dependant });
    } else {
      commit("binData", { storeModule, scope, keys });
    }
  }
};

const mutations: MutationTree<IDataModuleState> = {
  setData: (
    state,
    {
      storeModule,
      scope,
      total,
      messages = null,
      hash,
      data,
      params,
      merge = false
    }
  ) => {
    const dataPath = [storeModule, scope].join("/").replace(/\//g, ".");
    let scopeData = _.get(state, dataPath, {});
    const newScopeData = {};
    _.set(newScopeData, "total", total);
    _.set(newScopeData, "params", params);
    _.set(newScopeData, "hash", hash);
    _.set(newScopeData, "messages", messages);
    _.set(
      newScopeData,
      "data",
      _.isArray(data)
        ? _.keyBy(
            _.map(data, (i, index) => ({ ...i, _order: index })),
            "id"
          )
        : data
    );

    if (merge) {
      _.merge(scopeData, newScopeData);
    } else {
      scopeData = newScopeData;
    }

    setProp(state, dataPath.split("."), scopeData);
  },
  updateData: (state, { storeModule, scope, key = "", data }) => {
    const dataPath = [storeModule, scope].join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});
    // Potential BUG, if want to remove some properties
    _.merge(
      _.toString(key).length
        ? _.get(scopeData, `data.${key}`, {})
        : _.get(scopeData, `data`, {}),
      data
    ); // Mutate object
    setProp(state, dataPath.split("."), scopeData);
  },
  replaceData: (state, { storeModule, scope, data }) => {
    const dataPath = [storeModule, scope, "data"].join("/").replace(/\//g, ".");
    setProp(state, dataPath.split("."), data);
  },
  replaceByPath: (state, { storeModule, scope, path = "", data }) => {
    const dataPath = [storeModule, scope, path].join("/").replace(/\//g, ".");
    setProp(state, dataPath.split("."), data);
  },
  binData: (state, { storeModule, scope, keys = [] }) => {
    if (_.isEmpty(keys)) {
      const dataPath = [storeModule, scope].join("/").replace(/\//g, ".");
      deleteProp(state, dataPath.split("."));
    } else {
      keys.forEach(key => {
        const dataPath = [storeModule, scope, `data.${key}`]
          .join("/")
          .replace(/\//g, ".");
        deleteProp(state, dataPath.split("."));
        const totalPath = [storeModule, scope, "total"]
          .join("/")
          .replace(/\//g, ".");
        const currentTotal = _.toNumber(_.get(state, totalPath, 0));
        setProp(
          state,
          totalPath.split("."),
          currentTotal ? currentTotal - 1 : currentTotal
        );
      });
    }
  },
  binSingleItemList: (state, { storeModule, scope, path }) => {
    scope = scope || DEFAULT_LIST_SCOPE;
    const dataPath = [storeModule, scope, path].join("/").replace(/\//g, ".");
    deleteProp(state, dataPath.split("."));
    const totalPath = [storeModule, scope, "total"]
      .join("/")
      .replace(/\//g, ".");
    const currentTotal = _.toNumber(_.get(state, totalPath, 0));
    setProp(
      state,
      totalPath.split("."),
      currentTotal ? currentTotal - 1 : currentTotal
    );
  },
  addSingleItemList: (state, { storeModule, scope, path, data }) => {
    scope = scope || DEFAULT_LIST_SCOPE;
    let dataPath = [storeModule, scope, path].join("/").replace(/\//g, ".");
    setProp(state, dataPath.split("."), data);

    dataPath = [storeModule, scope, "data"].join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});
    const totalPath = [storeModule, scope, "total"]
      .join("/")
      .replace(/\//g, ".");
    setProp(state, totalPath.split("."), _.size(scopeData));
  },
  updateSingleItemList: (state, { storeModule, scope, path, data }) => {
    scope = scope || DEFAULT_LIST_SCOPE;
    const dataPath = [storeModule, scope, path].join("/").replace(/\//g, ".");
    setProp(state, dataPath.split("."), data);
  },
  setDependant: (state, { storeModule, scope, dependant }) => {
    const dataPath = [storeModule, scope].join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});
    const dependants = _.get(scopeData, `dependants`, []);
    _.set(scopeData, "dependants", _.union(dependants, [dependant]));
    setProp(state, dataPath.split("."), scopeData);
  },
  unsetDependant: (state, { storeModule, scope, dependant }) => {
    const dataPath = [storeModule, scope].join("/").replace(/\//g, ".");
    const scopeData = _.get(state, dataPath, {});
    const dependants = _.get(scopeData, `dependants`, []);
    _.pull(dependants, dependant); // Mutate array (removing dependant)
    _.set(scopeData, "dependants", dependants);
    setProp(state, dataPath.split("."), scopeData);
  }
};

export default {
  namespaced: true,
  getters: moduleGetters,
  actions,
  mutations,
  modules: {
    accounts: require("./accounts").default,
    affiliates: require("./affiliates").default,
    baskets: require("./baskets").default,
    brandGateways: require("./brandGateways").default,
    brands: require("./brands").default,
    bulkActions: require("./bulkActions").default,
    cardTypes: require("./cardTypes").default,
    catalogue: require("./catalogue").default,
    clients: require("./clients").default,
    contracts: require("./contracts").default,
    currencies: require("./currencies").default,
    customFields: require("./customFields").default,
    delegates: require("./delegates").default,
    documentation: require("./documentation").default,
    emails: require("./emails").default,
    fraudDetection: require("./fraudDetection").default,
    gatewayProviders: require("./gatewayProviders").default,
    gateways: require("./gateways").default,
    hooksLogs: require("./hooksLogs").default,
    images: require("./images").default,
    imports: require("./imports").default,
    invoices: require("./invoices").default,
    leads: require("./leads").default,
    legacyInvoices: require("./legacyInvoices").default,
    logs: require("./logs").default,
    manualExchangeRates: require("./manualExchangeRates").default,
    metaData: require("./metaData").default,
    notifications: require("./notifications").default,
    oauth: require("./oauth").default,
    oauthClients: require("./oauthClients").default,
    objectNotifications: require("./objectNotifications").default,
    orders: require("./orders").default,
    paymentDetails: require("./paymentDetails").default,
    payments: require("./payments").default,
    pricelists: require("./pricelists").default,
    priorities: require("./priorities").default,
    provisioning: require("./provisioning").default,
    reports: require("./reports").default,
    retentions: require("./retentions").default,
    segments: require("./segments").default,
    settings: require("./settings").default,
    shares: require("./shares").default,
    spamConditions: require("./spamConditions").default,
    stats: require("./stats").default,
    statuses: require("./statuses").default,
    supportDepartments: require("./supportDepartments").default,
    tags: require("./tags").default,
    tax: require("./tax").default,
    templates: require("./templates").default,
    termsAndConditions: require("./termsAndConditions").default,
    tickets: require("./tickets").default,
    translations: require("./translations").default,
    upmTracking: require("./upmTracking").default,
    userGroups: require("./userGroups").default,
    userNotifications: require("./userNotifications").default,
    users: require("./users").default,
    upmind: require("./upmind").default,
    wallets: require("./wallets").default,
    webhooks: require("./webhooks").default
  }
};
