import { UUID } from "@/data/enums";
import type { NavigationGuardNext, Route } from "vue-router";
import type { IUser } from "@/models/users";
import { AppHookCodes } from "@/data/enums/hooks";
import store from "@/store";
import _ from "@/boot/lodash";
import { Contexts } from "@/models/contexts";

// tslint:disable-next-line:no-var-requires
export default _.union(require("./auth").default, [
  {
    path: "/admin/auth/verify-user",
    alias: "/admin/verify-user",
    component: () => import("@/skeleton/admin/loggedOut/index.vue"),
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      store.commit("user", {});
      next();
    },
    children: [
      {
        path: "",
        component: () => import("@/views/admin/auth/verifyUser/index.vue"),
        meta: {
          title: "_.verify_email"
        }
      }
    ]
  },
  {
    name: "relayAdminToken",
    path: "/admin/auth/relay",
    alias: "/admin/relay",
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      if (window.opener) {
        await new Promise<void>(resolve => {
          const message = { source: "upmind", event: "client-ready" };
          let messageTimeout: any = null;
          const onMessage = async event => {
            if (!window.document.referrer.startsWith(event.origin)) return;
            if (_.get(event.data, "source") !== "upmind") return;
            if (_.get(event.data, "event") === "relay-token") {
              if (messageTimeout) clearTimeout(messageTimeout);
              store.commit("auth/admin/reset");
              await store.dispatch("auth/saveToken", {
                token: _.get(event.data, "data"),
                context: Contexts.ADMIN
              });
              window.removeEventListener("message", onMessage, false);
              resolve();
            }
          };
          window.addEventListener("message", onMessage);
          window.opener.postMessage(message, "*");
          messageTimeout = setTimeout(resolve, 250);
        });
      }
      next({ name: "adminDashboard", replace: true });
    }
  },
  {
    path: "/admin",
    name: "adminRoute",
    component: () => import("@/skeleton/admin/loggedIn/index.vue"),
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      // Regex to check "/admin" route
      const adminRegex = new RegExp("^/admin/?$", "gi");
      // If logged out, route to login with redirect (if applicable)
      if (!store.getters["auth/admin/isAuthenticated"]) {
        const redirect = to.fullPath.replace(adminRegex, "");
        return next({
          path: "/admin/login",
          query: redirect ? { redirect } : {},
          replace: true
        });
      }
      // If routing direct to "/admin" force to dashboard
      if (to.path.match(adminRegex)?.length)
        return next({ name: "adminDashboard", query: to.query, replace: true });

      // If entering from a non-admin route, initiate UI loading progress
      if (!from.path.startsWith("/admin")) store.commit("ui/loading", true);
      // Reset user – fixes behaviour issue with session modal
      store.commit("user", {});
      // Get admin user data before entering "authenticated" admin route
      const user: IUser = await store.dispatch("auth/admin/getUser");
      // Set brand based on previous selection or oauth client
      await store.dispatch("auth/admin/setBrand");
      // Fire hook
      await store.dispatch(
        `ui/hooks/${AppHookCodes.ADMIN_USER_LOGGED_IN}`,
        user
      );
      next();
    },
    children: _.union(
      [
        {
          path: "brand",
          name: "adminBrand",
          beforeEnter: async (
            to: Route,
            from: Route,
            next: NavigationGuardNext
          ) => {
            const brandId = to.params.id;
            try {
              const brand = await store.dispatch(
                "auth/admin/setBrand",
                brandId
              );
              window.$rootVue.$buefy.toast.open({
                message: window.$rootVue.$i18n.t("_.selected_brand", {
                  brand:
                    brand.name ||
                    (
                      window.$rootVue.$i18n.t("_.multi_brand_mode") as string
                    ).toLowerCase()
                }) as string
              });
            } catch (error) {
              window.$rootVue.$buefy.toast.open({
                message: _.get(error, "message"),
                type: "is-danger"
              });
            }
            store.dispatch("ui/endRouting");
            // Redirect to full path if org level
            return next(
              _.get(
                to.params,
                "redirect",
                brandId === UUID.ORG ? from.fullPath : from.path
              )
            );
          }
        }
      ],
      require("./account").default,
      require("./orders").default,
      require("./contractProducts").default,
      require("./baskets").default,
      require("./fraud").default,
      require("./billing").default,
      require("./clients").default,
      require("./leads").default,
      require("./logs").default,
      require("./dashboard").default,
      require("./insights").default,
      require("./settings").default,
      require("./support").default,
      require("./upmind").default
    )
  }
]);
