import i18n from "@/i18n";
import type { IInvoice } from "@/models/invoices";
import { InvoiceCategoryCode } from "@/models/invoices";

export enum InvoiceStatus {
  ADJUSTED = "invoice_adjusted",
  CANCELLED = "invoice_cancelled",
  DRAFT = "invoice_draft",
  OVERDUE = "invoice_overdue",
  PAID = "invoice_paid",
  REFUNDED = "invoice_refunded",
  REPLACED = "invoice_replaced", // Only on imported invoices
  UNPAID = "invoice_unpaid",
  CANCELLATION_REQUEST = "invoice_cancellation_request"
}

export const InvoiceStatusGroups = {
  PAID: [InvoiceStatus.PAID],
  UNPAID: [InvoiceStatus.UNPAID, InvoiceStatus.OVERDUE, InvoiceStatus.ADJUSTED],
  CREDITED: [InvoiceStatus.REFUNDED, InvoiceStatus.CANCELLED]
};

export enum CreditNoteStatus {
  ALLOCATED = "invoice_allocated",
  UNALLOCATED = "invoice_unallocated"
}

export enum InvoiceConsolidationTypes {
  DISABLED = 0,
  ENABLED = 1,
  INHERIT = 2
}

export enum InvoiceConsolidationRuleTypes {
  DAILY = "daily",
  DAY_OF_MONTH = "date_of_month",
  DAY_OF_WEEK = "day_of_week",
  FIRST_DAY_OF_MONTH = "first_day_of_month",
  LAST_DAY_OF_MONTH = "last_day_of_month"
}

export function InvoiceStatusMap(
  invoice: IInvoice,
  object = i18n.t("_.invoice"),
  showToBeCredited = false
) {
  const isToBeCredited = showToBeCredited && invoice.to_be_credited;
  const toBeCreditedMapping = {
    type: "is-info",
    icon: "hand-holding-usd",
    actions: [],
    label: i18n.t("_sentence.invoice.to_be_credited"),
    shortLabel: i18n.t("_.to_be_credited"),
    relative: {
      class: "has-text-info",
      path: `_.amount_due_on`,
      date: {
        datetime: invoice.due_date,
        formatFunction: "calendar"
      }
    },
    absolute: {
      path: "_sentence.object_unpaid_msg",
      date: {
        datetime: invoice.due_date,
        format: i18n.t("_datetime.date.default")
      }
    }
  };

  switch (invoice.status.code) {
    case InvoiceStatus.CANCELLED:
      return {
        type: "is-grey-light",
        icon: "ban",
        actions: [] as string[],
        label: i18n.t("_.object_cancelled", { object }),
        shortLabel: i18n.t("_.cancelled"),
        relative: {
          class: "has-text-grey-light",
          path: `_.cancelled_on`,
          date: {
            datetime: invoice.cancellation_datetime,
            formatFunction: "calendar"
          }
        },
        absolute: {
          path: "_sentence.object_cancelled_msg",
          date: {
            datetime: invoice.cancellation_datetime,
            format: i18n.t("_datetime.datetime.with_seconds")
          }
        }
      };
    case InvoiceStatus.OVERDUE:
      return isToBeCredited
        ? toBeCreditedMapping
        : {
            type: "is-danger",
            icon: "exclamation-circle",
            actions: ["pay", "credit"],
            label: i18n.t("_.object_overdue", { object }),
            shortLabel: i18n.t("_.overdue"),
            relative: {
              class: "has-text-danger",
              path: `_.amount_due_on`,
              date: {
                datetime: invoice.due_date,
                formatFunction: "calendar"
              }
            },
            absolute: {
              path: "_sentence.object_overdue_msg",
              date: {
                datetime: invoice.due_date,
                format: i18n.t("_datetime.date.default")
              }
            }
          };
    case InvoiceStatus.PAID:
      return {
        type: "is-success",
        icon: "check-circle",
        actions: [] as string[],
        label: i18n.t("_.object_paid", { object }),
        shortLabel: i18n.t("_.paid"),
        relative: {
          class: "has-text-success",
          path: `_.paid_on`,
          date: {
            datetime: invoice.paid_datetime,
            formatFunction: "calendar"
          }
        },
        absolute: {
          path: "_sentence.object_paid_msg",
          date: {
            datetime: invoice.paid_datetime,
            format: i18n.t("_datetime.datetime.with_seconds")
          }
        }
      };
    case InvoiceStatus.REFUNDED:
      return {
        type: "is-info",
        icon: "undo",
        actions: [] as string[],
        label: i18n.t("_.object_refunded", { object }),
        shortLabel: i18n.t("_.refunded"),
        relative: {
          class: "has-text-info",
          path: `_.refunded_on`,
          date: {
            datetime: invoice.refund_changed,
            formatFunction: "calendar"
          }
        },
        absolute: {
          path: "_sentence.object_refunded_msg",
          date: {
            datetime: invoice.refund_changed,
            format: i18n.t("_datetime.datetime.with_seconds")
          }
        }
      };
    case InvoiceStatus.ADJUSTED:
    case InvoiceStatus.UNPAID:
      return isToBeCredited
        ? toBeCreditedMapping
        : {
            type: "is-warning",
            icon: "info-circle",
            actions: ["pay", "credit"],
            label: i18n.t("_.object_unpaid", { object }),
            shortLabel: i18n.t("_.unpaid"),
            relative: {
              class: "has-text-warning",
              path: `_.amount_due_on`,
              date: {
                datetime: invoice.due_date,
                formatFunction: "calendar"
              }
            },
            absolute: {
              path: "_sentence.object_unpaid_msg",
              date: {
                datetime: invoice.due_date,
                format: i18n.t("_datetime.date.default")
              }
            }
          };
    default:
      return null;
  }
}

export function InvoiceCategoryCodesMap() {
  return {
    [InvoiceCategoryCode.ADDITIONAL_SERVICE]: {
      label: i18n.t("_.additional_service")
    },
    [InvoiceCategoryCode.CONSOLIDATION]: {
      label: i18n.t("_.consolidation")
    },
    [InvoiceCategoryCode.CREDIT_NOTE]: {
      label: i18n.t("_.credit_note")
    },
    [InvoiceCategoryCode.CREDIT_NOTE_FOR_REFUND]: {
      label: i18n.t("_.refund_credit_note")
    },
    [InvoiceCategoryCode.MIGRATION]: {
      label: i18n.t("_.migration")
    },
    [InvoiceCategoryCode.NEW_CONTRACT]: {
      label: i18n.t("_.new_order")
    },
    [InvoiceCategoryCode.ONE_TIME_SERVICE]: {
      label: i18n.t("_.one_time_service")
    },
    [InvoiceCategoryCode.RECURRENT]: {
      label: i18n.t("_.renewal")
    }
  };
}
