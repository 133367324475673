<template>
  <cprods-provider
    id="CPLNC"
    :data-scope="`$client_${clientId}_cp_needs_confirmation`"
    :client-id="clientId"
    :limit="0"
    :handle-loading="false"
    :manual-filters="manualFilters"
  >
    <template v-slot:default="{ $cProdsData }">
      <u-transition-slide-down>
        <template v-if="$cProdsData.total">
          <u-padding class="has-padding-y-300 has-padding-x-200">
            <columns class="is-marginless is-centered">
              <column class="is-12">
                <i18ntc
                  path="_plurals.new_product_almost_ready"
                  tag="h3"
                  :count="$cProdsData.products.length"
                  class="is-size-3 has-text-centered"
                />
                <i18n
                  path="_sentence.we_need_more_details"
                  tag="p"
                  class="has-text-grey has-text-centered has-margin-top-50"
                />
                <div
                  class="is-flex has-content-justified-center has-margin-y-150"
                >
                  <div :style="{ '--faux-progress': `${progress}%` }" />
                </div>
              </column>
              <column class="is-12 u-cprod-grid-items">
                <cprod-with-fields-grid-item
                  v-for="(cProd, index) in showAllProducts
                    ? $cProdsData.products
                    : $cProdsData.products.slice(0, 2)"
                  :key="`cprod-grid-item-${index}`"
                  :c-prod="cProd"
                  :show-skeletons="$cProdsData.isLoading || !$cProdsData.total"
                  :is-loading="$cProdsData.isLoading"
                />
              </column>
              <column
                v-if="!showAllProducts && $cProdsData.total > 2"
                class="is-12"
              >
                <load-more @loadMore="showAllProducts = true" />
              </column>
            </columns>
          </u-padding>
        </template>
      </u-transition-slide-down>
    </template>
  </cprods-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import type { IState } from "@/store";
import type { IClient } from "@/models/clients";

export default defineComponent({
  components: {
    CprodsProvider: () =>
      import("@/components/app/global/contractProducts/cProdsProvider.vue"),
    CprodWithFieldsGridItem: () =>
      import(
        "@/components/app/global/contractProducts/cProdWithFieldsGridItem.vue"
      )
  },
  data: () => ({
    manualFilters: {
      "filter[provision_setup_fields_confirmed]": false,
      exclude_delegated: 1
    },
    progress: 0,
    showAllProducts: false
  }),
  computed: {
    ...mapState({
      clientId: (state: IState): IClient["id"] => state?.user?.id || ""
    })
  },
  mounted() {
    setTimeout(() => {
      this.progress = 66;
    }, 1000);
  }
});
</script>

<style lang="scss" scoped>
div[style*="--faux-progress"] {
  display: block;
  position: relative;
  width: 100%;
  max-width: 20rem;
  height: 1rem;
  background: $light;
  border-radius: 1rem;

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--faux-progress, 0);
    border-radius: 1rem;
    background: $info;
    transition: width cubic-bezier(0.57, 0.89, 0.59, 0.96) 600ms;
  }
}

.u-cprod-grid-items {
  display: grid;
  gap: 0.75rem;
  justify-content: center;

  @include touch {
    grid-template-columns: repeat(auto-fit, minmax(auto, 36rem));
  }

  @include desktop {
    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
    & > :only-child {
      grid-column: 1 / span 2;
      width: 100%;
      max-width: 36rem;
      justify-self: center;
    }
  }
}
</style>
