<template>
  <div
    :class="{
      'slide-modal-card': isSlide,
      'modal-card': !isSlide
    }"
  >
    <header v-if="title" class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
      <button
        v-if="canCancelByX"
        type="button"
        class="modal-close modal-header-close is-large"
        @click="parentModal.cancel('x')"
      />
    </header>
    <section
      class="modal-card-body"
      :class="{
        'is-paddingless': hasPaddinglessBody
      }"
    >
      <slot />
    </section>

    <slot v-if="showFooter" name="footer-all">
      <footer class="modal-card-foot has-content-justified-between">
        <template v-if="canCancelByButton">
          <b-button
            :disabled="isProcessing"
            type="is-light"
            tabindex="-1"
            @click="parentModal.cancel('button')"
            >{{ cancelLabel }}</b-button
          >
        </template>
        <span v-else />
        <slot name="footer" />
      </footer>
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";
import type { TranslateResult } from "vue-i18n";
import type { Route } from "vue-router";

export default defineComponent({
  name: "ModalCard",
  props: {
    title: { type: String, default: "" },
    isProcessing: { type: Boolean, default: false },
    hasPaddinglessBody: { type: Boolean, default: false },
    cancelText: {
      type: String as PropType<string | TranslateResult>,
      default: "_action.cancel"
    },
    showFooter: { type: Boolean, default: true },
    blockCancellation: { type: Boolean, default: false },
    autoCloseOnRouteChange: { type: Boolean, default: true }
  },
  computed: {
    cancelLabel() {
      return (this.cancelText as string).match(/^_\w*\.[\w.]+$/g)
        ? this.$t(this.cancelText as string)
        : this.cancelText;
    },
    canCancelByButton() {
      const cancelOptions = this.parentModal.cancelOptions;
      return cancelOptions && cancelOptions.includes("button");
    },
    canCancelByX() {
      const cancelOptions = this.parentModal.cancelOptions;
      return cancelOptions && cancelOptions.includes("x");
    },
    parentModal() {
      const parent = this.getParentModal(this);
      return parent || this;
    },
    isSlide(): boolean {
      return this.parentModal && this.parentModal.__SLIDE__;
    }
  },
  watch: {
    blockCancellation: { handler: "onBlockCancellation", immediate: true }
  },
  created() {
    const el: any = document.querySelector(":focus");
    if (el) {
      el.blur();
    }
  },
  mounted() {
    this.$bus.$on(`route-path-changed`, this.onRouteChange);
  },
  beforeDestroy() {
    this.$bus.$off(`route-path-changed`, this.onRouteChange);
  },
  methods: {
    onBlockCancellation(newVal) {
      if (
        this.$_.isFunction(
          this.$_.get(this, "parentModal.setBlockCancellation")
        )
      ) {
        this.parentModal.setBlockCancellation(newVal);
      }
    },
    onRouteChange(change: { to: Route; from: Route }) {
      this.$emit("route-change", change);
      if (this.autoCloseOnRouteChange) this.$emit("close");
    },
    getParentModal(vue) {
      if (!vue.$parent) return vue;
      return ["BModal"].includes(
        this.$_.get(vue, "constructor.options.name")
      ) || vue.__SLIDE__
        ? vue
        : this.getParentModal(vue.$parent);
    }
  }
});
</script>

<style lang="scss" scoped>
.modal-card {
  &.is-danger {
    .modal-card-head {
      background-color: $danger;
      .modal-card-title {
        color: $danger-invert;
      }
    }
  }

  &.is-caution {
    .modal-card-head {
      background-color: $caution;
      .modal-card-title {
        color: $caution-invert;
      }
    }
  }

  &.is-primary {
    .modal-card-head {
      background-color: $primary;
      .modal-card-title {
        color: $primary-invert;
      }
    }
  }

  .modal-card-head {
    text-align: center;
    background-color: $primary;
    background-color: var(--brand-color);

    .modal-card-title {
      color: $primary-invert;
      color: var(--brand-color-contrast);
    }

    .modal-close {
      position: absolute;
      right: 1rem;
    }
  }

  .modal-card-body:first-child {
    border-top-left-radius: $radius-large;
    border-top-right-radius: $radius-large;
  }

  .modal-card-body:last-child {
    border-bottom-left-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
  }
}

.slide-modal-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  .modal-card-head {
    border-radius: 0px;
    background-color: $primary;
    background-color: var(--brand-color);
    .modal-card-title {
      text-align: left;
      color: $primary-invert;
      color: var(--brand-color-contrast);
    }
    .columns {
      width: 100%;
    }
  }
  .modal-card-foot {
    border-radius: 0px;
  }

  ::v-deep.filter-form {
    // This fixes filter modal inside the modal
    top: -1rem;
  }
}
</style>
