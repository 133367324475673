const DATA_LAYER_NAME = "upmDataLayer";
export const UPM_DATA_LAYER = (window[DATA_LAYER_NAME] =
  window[DATA_LAYER_NAME] || []);

export function dataLayerPush() {
  // eslint-disable-next-line prefer-rest-params
  UPM_DATA_LAYER.push(arguments);
}

export function dataLayer(...args) {
  // @ts-ignore
  dataLayerPush(...args);
}

export function install(GTM_CONTAINER_ID) {
  dataLayer("gtm.start", new Date().getTime());
  dataLayer("event", "gtm.js");
  const first = document.getElementsByTagName("script")[0];
  const script = document.createElement("script");
  script.async = true;
  script.setAttribute(
    "src",
    `https://www.googletagmanager.com/gtm.js?id=${GTM_CONTAINER_ID}&l=${DATA_LAYER_NAME}`
  );
  first.parentNode?.insertBefore(script, first);
}

export default {
  install: (Vue: any) => {
    Object.defineProperty(Vue.prototype, "$gtagInstall", {
      value: GTM_CONTAINER_ID => install(GTM_CONTAINER_ID)
    });
    Object.defineProperty(Vue.prototype, "$dataLayer", {
      value: dataLayer
    });
  }
};
