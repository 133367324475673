import { AdminRoutes, RegexMatch } from "@/data/enums/router";
import { DocCategories } from "@/data/enums/docs";
import { DataModules } from "@/store/modules/data/modules";
import type { RouteRecord } from "vue-router";
import store from "@/store";
import {
  InvoiceStatusParam,
  FromLast30DaysParam,
  FraudStatusParam,
  DefaultInvoicesFilters,
  InvoiceStatusFilter
} from "@/data/filters/invoice";
import { InvoiceStatus, InvoiceStatusGroups } from "@/data/enums/invoice";
import { FraudStatus } from "@/data/enums";

import InvoicesListing from "@/components/app/global/invoices/invoicesListing.vue";
import {
  DateCancelledSorter,
  DefaultInvoicesSorters
} from "@/data/sorters/invoices";
import _ from "@/boot/lodash";

export default [
  {
    path: "billing",
    components: {
      default: {
        name: "AdminBilling",
        template: `<router-view />`
      },
      menu: () => import("@/components/base/MenuComponent.vue")
    },
    props: {
      menu: {
        routes: require("./menu").default
      }
    },
    meta: {
      breadcrumb: {
        label: "_.billing"
      }
    },
    children: [
      {
        path: "",
        name: "adminBilling",
        redirect: "invoices"
      },
      {
        path: "invoices",
        component: {
          name: "AdminBillingInvoices",
          template: `<router-view />`
        },
        meta: {
          categorySlug: DocCategories.USING_UPMIND,
          docsSlugs: ["invoicing-and-payments"]
        },
        children: [
          {
            path: "",
            name: "adminInvoices",
            component: () => import("@/views/admin/billing/invoices/index.vue"),
            props: {
              routes: require("./menu").invoicesTabigation
            },
            meta: {
              auth: ["list_invoices"],
              title: "_.invoices"
            },
            redirect: { name: "adminInvoicesOverview" },
            children: [
              {
                path: "",
                name: "adminInvoicesOverview",
                components: {
                  default: () =>
                    import("@/views/admin/billing/invoices/overview/index.vue")
                },
                meta: {
                  auth: ["list_invoices"],
                  categorySlug: DocCategories.USING_UPMIND,
                  docsSlugs: ["invoicing-and-payments"]
                },
                children: [
                  {
                    path: `:invoiceId(${RegexMatch.UUID})`,
                    name: AdminRoutes.INVOICE_PREVIEW,
                    beforeEnter: (to, from, next) => {
                      next();
                      store.dispatch(
                        `data/${DataModules.INVOICES}/openPreviewModal`,
                        to.params
                      );
                    }
                  }
                ] as RouteRecord[]
              },
              {
                path: "list",
                name: "adminInvoicesList",
                components: {
                  default: () =>
                    import("@/views/admin/billing/invoices/list/index.vue")
                },
                meta: {
                  auth: ["list_invoices"]
                },
                redirect: { name: "adminInvoicesList_all" },
                children: [
                  {
                    path: "",
                    component: {
                      name: "AdminInvoicesListView",
                      template: `<router-view :key="$route.name"/>`
                    },
                    children: [
                      {
                        path: "all",
                        name: "adminInvoicesList_all",
                        component: InvoicesListing,
                        props: () => ({
                          availableFilters: () => [
                            ...DefaultInvoicesFilters(),
                            InvoiceStatusFilter()
                          ]
                        })
                      },
                      {
                        path: "paid",
                        name: "adminInvoicesList_paid",
                        component: InvoicesListing,
                        props: () => ({
                          availableFilters: DefaultInvoicesFilters,
                          manualFilters: () =>
                            InvoiceStatusParam(...InvoiceStatusGroups.PAID)
                        })
                      },
                      {
                        path: "unpaid",
                        name: "adminInvoicesList_unpaid",
                        component: InvoicesListing,
                        props: () => ({
                          availableFilters: DefaultInvoicesFilters,
                          manualFilters: () =>
                            InvoiceStatusParam(...InvoiceStatusGroups.UNPAID)
                        })
                      },
                      {
                        path: "overdue",
                        name: "adminInvoicesList_overdue",
                        component: InvoicesListing,
                        props: () => ({
                          availableFilters: DefaultInvoicesFilters,
                          manualFilters: () => ({
                            ...InvoiceStatusParam(InvoiceStatus.OVERDUE)
                          })
                        })
                      },
                      {
                        path: "cancelled",
                        name: "adminInvoicesList_cancelled",
                        component: InvoicesListing,
                        props: () => ({
                          availableFilters: DefaultInvoicesFilters,
                          availableSorters: () =>
                            _.orderBy(
                              [
                                ...DefaultInvoicesSorters(),
                                DateCancelledSorter()
                              ],
                              ["label"],
                              ["asc"]
                            ),
                          manualFilters: () => ({
                            ...InvoiceStatusParam(InvoiceStatus.CANCELLED)
                          })
                        })
                      },
                      {
                        path: "refunded",
                        name: "adminInvoicesList_refunded",
                        component: InvoicesListing,
                        props: () => ({
                          availableFilters: DefaultInvoicesFilters,
                          manualFilters: () =>
                            InvoiceStatusParam(InvoiceStatus.REFUNDED)
                        })
                      },
                      {
                        path: "from_last_30_days",
                        name: "adminInvoicesList_last_30_days",
                        component: InvoicesListing,
                        props: () => ({
                          availableFilters: () => [
                            ...DefaultInvoicesFilters(),
                            InvoiceStatusFilter()
                          ],
                          manualFilters: () => FromLast30DaysParam()
                        })
                      },
                      {
                        path: "pending_review",
                        name: "adminInvoicesList_pending_review",
                        component: InvoicesListing,
                        props: () => ({
                          availableFilters: () => [
                            ...DefaultInvoicesFilters(),
                            InvoiceStatusFilter()
                          ],
                          manualFilters: () =>
                            FraudStatusParam(FraudStatus.REVIEW)
                        })
                      },
                      {
                        path: "blocked",
                        name: "adminInvoicesList_blocked",
                        component: InvoicesListing,
                        props: () => ({
                          availableFilters: () => [
                            ...DefaultInvoicesFilters(),
                            InvoiceStatusFilter()
                          ],
                          manualFilters: () =>
                            FraudStatusParam(FraudStatus.FRAUD)
                        })
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "legacy-invoices",
        name: "adminLegacyInvoices",
        component: () =>
          import("@/views/admin/billing/legacyInvoices/index.vue"),
        meta: {
          auth: ["list_import_invoices"],
          title: "_.legacy_invoices",
          categorySlug: DocCategories.USING_UPMIND,
          docsSlugs: ["invoicing-and-payments"]
        },
        label: "_.legacy_invoices"
      },
      {
        path: "cancellation-requests",
        name: "adminCancellationRequests",
        component: () =>
          import(
            "@/views/admin/billing/contract-cancellation-requests/index.vue"
          ),
        meta: {
          auth: ["list_contract_cancellation_request"],
          title: "_.cancellation_requests",
          categorySlug: DocCategories.USING_UPMIND,
          docsSlugs: ["invoicing-and-payments"]
        }
      },
      {
        path: "credit-notes",
        name: "adminCreditNotes",
        component: () => import("@/views/admin/billing/credit-notes/index.vue"),
        props: {
          routes: require("./menu").creditNotesQuickFilters
        },
        meta: {
          auth: ["list_invoices"],
          title: "_.credit_notes",
          backTo: "_.credit_notes",
          categorySlug: DocCategories.USING_UPMIND,
          docsSlugs: ["invoicing-and-payments"]
        }
      },
      {
        path: "transactions",
        component: {
          name: "AdminBillingTransactions",
          template: `<router-view />`
        },
        children: [
          {
            path: "",
            name: "adminTransactions",
            component: () =>
              import("@/views/admin/billing/transactions/index.vue"),
            meta: {
              auth: ["list_payment_logs"],
              title: "_.transactions"
            },
            redirect: "/admin/billing/transactions",
            children: [
              {
                path: "",
                components: {
                  default: () =>
                    import(
                      "@/views/admin/billing/transactions/overview/index.vue"
                    )
                },
                props: {
                  default: {
                    routes: require("./menu").transactionsQuickFilters
                  }
                },
                meta: {
                  auth: ["list_payment_logs"]
                },
                children: [
                  {
                    path: "",
                    name: "adminTransactionsOverview",
                    components: {
                      default: () =>
                        import(
                          "@/components/app/admin/transactions/transactionsOverview.vue"
                        )
                    },
                    props: {
                      default: {
                        routes: require("./menu").transactionsQuickFilters
                      }
                    },
                    meta: {
                      categorySlug: DocCategories.USING_UPMIND,
                      docsSlugs: ["invoicing-and-payments"]
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "retentions/:statusId?",
        name: "adminRetentions",
        component: () => import("@/views/admin/billing/retentions/index.vue"),
        meta: {
          auth: [],
          title: "_.retentions"
        }
      },
      {
        path: "*",
        component: () => import("@/views/error/index.vue"),
        props: { code: 404 }
      }
    ]
  }
];
