import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";

import VueTextareaAutosize from "vue-textarea-autosize";
import VueTheMask from "vue-the-mask";

import BaseComponents from "@/components/base";
import AppBaseComponents from "@/components/app-base";
import BaseLayouts from "@/ui/layouts";
import BaseColumns from "@/ui/columns";
import BaseGrids from "@/ui/grids";
import directives from "@/directives";

import _ from "@/boot/lodash";
import Buefy from "@/boot/buefy";
import UUID from "@/boot/uuid";
import Validation from "@/boot/validation";
import Gtm from "@/boot/gtm";
import Sentry from "@/boot/sentry";
import VueClipboard from "@/boot/vue-clipboard";
import VueScrollTo from "@/boot/vue-scrollto";
// import VueSocketIO from "@/boot/vue-socket-io";
import EventBus from "@/boot/event-bus";
import MomentJs from "@/boot/moment-js";
import VueLodash from "@/boot/vue-lodash";
import UserCan from "@/boot/user-can";

import Slider from "@/plugins/Slide";

import ComponentMixin from "@/mixins/component";
import { OrgFeatureKeys } from "@/data/constants";

import "../typings/vue";

Vue.config.productionTip = false;

Vue.use(AppBaseComponents);
Vue.use(BaseColumns);
Vue.use(BaseComponents);
Vue.use(BaseGrids);
Vue.use(BaseLayouts);
Vue.use(Buefy);
Vue.use(EventBus);
Vue.use(Gtm);
Vue.use(MomentJs);
Vue.use(Sentry);
Vue.use(Slider);
Vue.use(UUID);
Vue.use(UserCan);
Vue.use(Validation);
Vue.use(VueClipboard);
Vue.use(VueLodash);
Vue.use(VueScrollTo);
// Vue.use(VueSocketIO);
Vue.use(VueTextareaAutosize);
Vue.use(VueTheMask);
Vue.use(directives);

Vue.mixin(ComponentMixin);

declare global {
  interface Window {
    $rootVue: Vue;
  }
}

const initializeApp = async () => {
  // eslint-disable-next-line no-console
  console.info(
    "%cUPM-Version:",
    "color:rgb(1, 143, 253);font-weight:bold;",
    import.meta.env.RELEASE
  );

  /** First: We retrieve brand settings and org config values, as these are required
   * by subsequent boot actions. For example, we need to know the supported brand
   * languages before verifying and setting the default locale */

  await Promise.all([
    store.dispatch("brand/get"),
    store.dispatch("org/getConfig", {
      keys: Object.values(OrgFeatureKeys)
    })
  ]);

  /** Next: We set the default locale */

  await store.dispatch("i18n/setDefaultLocale");

  /** Next: We load global language packs and org modules (plus their
   * respective lang files) for the supported active locale */

  await Promise.all([
    store.dispatch("org/getAndRegisterModules"),
    store.dispatch("i18n/loadLanguagePacks", {
      packs: [
        "_",
        "_action",
        "_error",
        "_hook",
        "_sentence",
        "_validation",
        "_placeholder",
        "_datetime",
        "_plurals"
      ]
    })
  ]);

  /** Finally: Vue app is initialised */

  window.$rootVue = new Vue({
    i18n,
    router,
    el: "#app",
    store,
    created() {
      this.commitWindowValues();
      // Listen for window resize
      window.addEventListener(
        "resize",
        this.$_.debounce(this.commitWindowValues, 250)
      );
      // Listen for visibility change
      document.addEventListener("visibilitychange", this.commitWindowValues);
      this.$store.dispatch("utils/time/start");

      this.$bus.$on("scrollToTop", (target = "body") => {
        document
          .querySelectorAll(`[data-scroll-target="${target}"]`)
          .forEach(target => {
            target.scrollTop = 0;
          });
      });

      this.$bus.$on("scrollToBottom", (target = "body") => {
        document
          .querySelectorAll(`[data-scroll-target="${target}"]`)
          .forEach(target => {
            target.scrollTop = target.scrollHeight;
          });
      });
    },
    methods: {
      getWindowWidth() {
        return this.$_.max([
          document.documentElement.clientWidth,
          window.innerWidth || 0
        ]) as number;
      },
      getWindowHeight() {
        return this.$_.max([
          document.documentElement.clientHeight,
          window.innerHeight || 0
        ]) as number;
      },
      commitWindowValues() {
        this.$store.commit("ui/window", {
          width: this.getWindowWidth(),
          height: this.getWindowHeight(),
          visible: document.visibilityState === "visible"
        });
      }
    },
    render: h => h(App)
  });
};

const listenForSuspectChunkErrors = () => {
  if (import.meta.env.NODE_ENV === "production") {
    let errorCount = 0;
    window.addEventListener(
      "error",
      e => {
        // Check for suspect SyntaxError
        const errorName = _.get(e, "error.name");
        const errorMessage = _.get(e, "error.message", "");
        const errorFile = _.get(e, "filename", "");
        const isSuspectSyntaxError =
          errorName === "SyntaxError" &&
          errorMessage.endsWith("'<'") &&
          errorFile.startsWith(location.origin) &&
          errorFile.endsWith(".js");
        // Check for suspect GET error (404)
        const targetNode = _.get(e, "target.nodeName");
        const targetFile = _.get(e, "target.src", _.get(e, "target.href", ""));
        const isSuspectGetError =
          ["SCRIPT", "LINK"].includes(targetNode) &&
          targetFile.startsWith(location.origin) &&
          targetFile.endsWith(".js");
        // If error is suspect...
        if (isSuspectSyntaxError || isSuspectGetError) {
          if (!errorCount++) {
            const confirm = window.confirm(
              `${location.host} has encountered an unexpected issue. Please click 'Ok' to reload the page.`
            );
            if (confirm) window.location.reload();
          }
        }
      },
      true
    );
  }
};

if (document.getElementById("app")) {
  listenForSuspectChunkErrors();
  initializeApp();
}
