import i18n from "@/i18n";
import { FilterOperators } from "@/data/table";
import { FraudStatus } from "@/data/enums";
import { serialiseConfig } from "@/helpers/table";

export function ClientsTabigation() {
  return [
    {
      label: i18n.t("_.overview"),
      to: {
        name: `adminClientsOverview`
      }
    },
    {
      label: i18n.t("_.object_list", {
        object: i18n.t("_.clients") as string
      }),
      to: {
        name: `adminClientsList`
      }
    }
  ];
}

export const clientsQuickFilters = [
  {
    label: "_.all_clients",
    to: {
      name: "adminClientsList"
    }
  },
  {
    label: "_.all_clients_excluding_guests",
    to: {
      name: "adminClientsList",
      query: {
        CL1: serialiseConfig({
          constFilters: [
            {
              key: "is_guest",
              operator: FilterOperators.EQUAL,
              value: "false"
            }
          ]
        })
      }
    }
  },
  {
    label: "_.unverified_clients",
    to: {
      name: "adminClientsList",
      query: {
        CL1: serialiseConfig({
          constFilters: [
            {
              key: "verified",
              operator: FilterOperators.EQUAL,
              value: "0"
            }
          ]
        })
      }
    }
  },
  {
    label: "_.clients_from_last_30_days",
    to: {
      name: "adminClientsList",
      query: {
        CL1: serialiseConfig({
          constFilters: [
            {
              key: "created_at",
              operator: FilterOperators.AFTER_RELATIVE,
              value: "-1_months"
            }
          ]
        })
      }
    }
  },
  {
    label: "_.pending_review",
    to: {
      name: "adminClientsList",
      query: {
        CL1: serialiseConfig({
          constFilters: [
            {
              key: "fraud_status",
              operator: FilterOperators.EQUAL,
              value: `${FraudStatus.REVIEW}`
            }
          ]
        })
      }
    }
  },
  {
    label: "_.blocked",
    to: {
      name: "adminClientsList",
      query: {
        CL1: serialiseConfig({
          constFilters: [
            {
              key: "fraud_status",
              operator: FilterOperators.EQUAL,
              value: `${FraudStatus.FRAUD}`
            }
          ]
        })
      }
    }
  }
];
