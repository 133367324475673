export enum DataModules {
  ACCOUNTS = "accounts",
  AFFILIATES = "affiliates",
  AFFILIATES_COMMISSIONS = "affiliates/commissions",
  AFFILIATES_LINKS = "affiliates/links",
  AFFILIATES_PAYOUTS = "affiliates/payouts",
  AFFILIATES_PAYOUT_DESTINATIONS = "affiliates/payoutDestinations",
  AFFILIATES_REFERRALS = "affiliates/referrals",
  AFFILIATES_STATS = "affiliates/stats",
  AFFILIATES_TIERS = "affiliates/tiers",
  AFFILIATES_TIERS_CONDITIONS = "affiliates/tiers/conditions",
  AFFILIATES_TIERS_CONDITIONS_PRODUCTS = "affiliates/tiers/conditions/products",
  API_TOKENS = "users/apiTokens",
  BASKETS = "baskets",
  BRANDS = "brands",
  BRAND_GATEWAYS = "brandGateways",
  BULK_ACTIONS = "bulkActions",
  BULK_ACTIONS_RESULTS = "bulkActions/results",
  CARD_TYPES = "cardTypes",
  CATALOGUE = "catalogue",
  CATALOGUE_CATEGORIES = "catalogue/categories",
  CATALOGUE_MAIN_PRODUCT_CATEGORIES = "catalogue/mainProductCategories",
  CATALOGUE_PROMOTIONS = "catalogue/promotions",
  CLIENTS = "clients",
  CLIENTS_ACCOUNTS = "clients/accounts",
  CLIENTS_ADDRESSES = "clients/addresses",
  CLIENTS_CHILD_ACCOUNTS = "clients/childAccounts",
  CLIENTS_COMPANIES = "clients/companies",
  CLIENTS_EMAILS = "clients/emails",
  CLIENTS_PAYMENT_DETAILS = "clients/paymentDetails",
  CLIENTS_PHONES = "clients/phones",
  CLIENTS_VAULT_ASSETS = "clients/vaultAssets",
  CONTRACTS = "contracts",
  CONTRACTS_CANCELLATION_REQUESTS = "contracts/cancellationRequests",
  CONTRACTS_CATEGORIES = "contracts/categories",
  CONTRACTS_PRODUCTS = "contracts/products",
  CURRENCIES = "currencies",
  CUSTOM_BASKET_FIELDS = "customFields/basket",
  CUSTOM_CANCELLATION_FIELDS = "customFields/cancellation",
  CUSTOM_CLIENT_FIELDS = "customFields/clients",
  CUSTOM_FIELDS = "customFields",
  DELEGATES = "delegates",
  DOCUMENTATION = "documentation",
  EMAILS = "emails",
  FRAUD_DETECTION = "fraudDetection",
  FRAUD_DETECTION_EVENTS = "fraudDetection/events",
  FRAUD_DETECTION_POLICIES = "fraudDetection/policies",
  GATEWAYS = "gateways",
  GATEWAYS_PROVIDERS = "gatewayProviders",
  HOOKS_LOGS = "hooksLogs",
  HOST_MODULE = "web_hosting",
  HOST_MODULE_TLDS = "web_hosting/tlds",
  IMAGES = "images",
  IMPORTS = "imports",
  IMPORTS_FILES = "imports/files",
  IMPORTS_LOGS = "imports/logs",
  IMPORTS_STEPS = "imports/steps",
  IMPORT_MAPPINGS = "imports/mappings",
  IMPORTS_UPLOAD_FILES = "imports/uploadFiles",
  INVOICES = "invoices",
  INVOICES_BASKETS = "invoices/baskets",
  INVOICES_PDFS = "invoices/pdfs",
  LEADS = "leads",
  LEADS_VAULT_ASSETS = "leads/vaultAssets",
  LEGACY_INVOICES = "legacyInvoices",
  LOGS = "logs",
  MANUAL_EXCHANGE_RATES = "manualExchangeRates",
  META_DATA = "metaData",
  NOTIFICATIONS_CATEGORIES = "notifications/categories",
  NOTIFICATIONS_CHANNELS = "notifications/channels",
  NOTIFICATIONS_HOOKS = "notifications/hooks",
  NOTIFICATIONS_RECIPIENT_TYPES = "notifications/channels",
  OAUTH = "oauth",
  OAUTH_AUTHORIZATIONS = "oauth/authorizations",
  OAUTH_CLIENTS = "oauthClients",
  OAUTH_TOKENS = "oauth/tokens",
  OBJECT_NOTIFICATIONS = "objectNotifications",
  ORDERS = "orders",
  ORDERS_TEMPLATES = "orders/templates",
  PAYMENTS = "payments",
  PAYMENT_DETAILS = "paymentDetails",
  PRICELISTS = "pricelists",
  PRIORITIES = "priorities",
  PROVISIONING = "provisioning",
  PROVISIONING_BASKET_PRODUCTS = "provisioning/basketProducts",
  PROVISIONING_BLUEPRINTS = "provisioning/blueprints",
  PROVISIONING_BRAND = "provisioning/brand",
  PROVISIONING_CATEGORIES = "provisioning/categories",
  PROVISIONING_CONFIGURATIONS = "provisioning/configurations",
  PROVISIONING_CONTRACT_PRODUCTS = "provisioning/contractProducts",
  PROVISIONING_FUNCTIONS = "provisioning/functions",
  PROVISIONING_PROVIDERS = "provisioning/providers",
  PROVISIONING_REQUESTS = "provisioning/requests",
  REPORTS = "reports",
  REPORTS_REVENUE = "reports/revenue",
  RETENTIONS = "retentions",
  RETENTIONS_LOGS = "retentions/logs",
  RETENTIONS_REASONS = "retentions/reasons",
  SEGMENTS = "segments",
  SEGMENTS_RESULTS = "segments/results",
  SETTINGS = "settings",
  SETTINGS_CATEGORIES = "settings/categories",
  SHARES = "shares",
  SPAM_CONDITIONS = "spamConditions",
  STATS = "stats",
  STATUSES = "statuses",
  SUPPORT_DEPARTMENTS = "supportDepartments",
  SUPPORT_DEPARTMENTS_BRAND = "supportDepartments/brands",
  TAGS = "tags",
  TAX = "tax",
  TAX_LOCATIONS = "tax/locations",
  TAX_TAGS = "tax/tags",
  TAX_TEMPLATES = "tax/templates",
  TEMPLATES = "templates",
  TEMPLATES_CLIENT = "templates/client",
  TEMPLATES_CLIENT_MAPPINGS = "templates/client/mappings",
  TEMPLATES_CLIENT_RENDER = "templates/client/render",
  TEMPLATES_CLIENT_SLOTS = "templates/client/slots",
  TEMPLATES_CONTENTS = "templates/contents",
  TERMS_AND_CONDITIONS = "termsAndConditions",
  TICKETS = "tickets",
  TICKETS_DEPARTMENTS = "tickets/departments",
  TICKETS_FILES = "tickets/files",
  TICKETS_MESSAGES = "tickets/messages",
  TRANSLATIONS = "translations",
  UPMIND = "upmind",
  UPM_TACKING = "upmTracking",
  USERS = "users",
  USER_GROUPS = "userGroups",
  USER_NOTIFICATIONS = "userNotifications",
  USER_TICKET_DEPARTMENTS = "users/ticketDepartments",
  WALLETS = "wallets",
  WEBHOOKS = "webhooks",
  WEBHOOKS_EVENTS = "webhooks/events",
  WEBHOOKS_TRIGGERS = "webhooks/triggers"
}
